import React from "react";

const Select = ({ handleSelectChange, options, name, value, title }) => {
  return (
    <div>
      <div className="position-relative">
        <span className="selectcountry-hint">{title}</span>
        <select
          className="countrySelect border-radius-8"
          value={value}
          name={name}
          onChange={handleSelectChange}
        >
          <option disabled value="" id="">
            Select {name}
          </option>
          {options?.map((option) => (
            <option value={option} id={name}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
