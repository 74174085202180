import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';

import OthersModal from './modals/OthersModal';

export const StatusBtns = ({ handleStatus, status, showError }) => {
  const [state, setstate] = useState(status);

  useEffect(() => {
    setstate(status);
  }, [status]);

  const handleState = () => {
    if (state === 'pending') {
      showError();
    } else {
      handleStatus(state);
    }
  };

  const btns = [
    'Available',
    // "Back Order",
    'Not Available',
    'Discontinued',
  ];
  return (
    <>
      <div className="my-3 p-3 border">
        <h2 className="text-center color-black my-4">
          Select Your Product Status
        </h2>
        <div className="mt-3 d-flex align-items-center justify-content-between flex-wraped">
          {btns.map((btn) => (
            <button
              onClick={() => setstate(btn)}
              className={`${
                btn === state
                  ? 'background-secondary color-white'
                  : 'bg-transparent'
              } border border-radius-8 mx-2 p-3 m-2 c-pointer color-black`}
            >
              {btn}
            </button>
          ))}
        </div>
        <div className="d-flex justify-content-center">
          <button
            style={{ marginLeft: '-24px' }}
            className="color-white p-2 btn no-border border-radius-8 d-block mt-3"
            onClick={() => handleState()}
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export const ShipperBtns = ({ handleShipping, selected, showError }) => {
  const btns = ["FedEx", "UPS", "USPS", "Other"];
  const [open, setOpen] = useState(false);
  const [selectedShipper, setSelectedShipper] = useState("");
  const handleSubmit = (shipperName) => {
    if (shipperName !== "") {
      handleShipping(shipperName);
      setOpen(false);
    } else showError();
  };

  const handleClick = (text) => {
    if (text !== "Other") {
      if (text !== "") return handleShipping(text);
      else return showError();
    }
    setOpen(true);
  };
  const handleShipperSelection = (name) => setSelectedShipper(name);
  return (
    <div className="my-3 p-3 border">
      <h2 className="text-center my-4 color-black">Select Shipper</h2>
      <div className="mt-3 d-flex align-items-center justify-content-between flex-wraped">
        {btns.map((btn) => (
          <button
            className={`${
              btn === selectedShipper
                ? "background-secondary  color-light"
                : "bg-transparent"
            } border border-radius-8 mx-2 p-3 m-2 c-pointer color-black`}
            onClick={() => handleShipperSelection(btn)}
          >
            {btn}
          </button>
        ))}
      </div>
      <button
        className="background-primary d-block color-white border border-radius-8 mx-auto p-3 c-pointer color-white"
        onClick={() => handleClick(selectedShipper)}
      >
        Update
      </button>
      <OthersModal
        handleSubmit={handleSubmit}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </div>
  );
};

export const TrackingBtns = ({
  handleTrackingNum,
  defaultValue,
  showError,
}) => {
  const [value, setvalue] = useState("");
  const handleState = () => {
    console.log(value);
    if (value !== "") handleTrackingNum(value);
    else showError();
  };
  return (
    <div className="my-3 p-3 border">
      <h2 className="text-center my-4">Please Enter Tracking#</h2>
      <div className="mt-3 d-flex align-items-center justify-content-between flex-wraped">
        <div className="w-100">
          <TextField
            id="outlined-basic"
            label="Tracking #"
            variant="outlined"
            className="w-100"
            value={value}
            onChange={(e) => setvalue(e.target.value)}
          />
          <div className="my-12">
            <button
              className="mx-auto background-primary color-white p-3 c-pointer border border-radius-8 d-block"
              onClick={() => handleState()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShippingDaysBtns = ({
  handleDays,
  defaultValue,
  notPastDate,
  showError,
}) => {
  const [value, setvalue] = useState(defaultValue);
  const handleDateChange = (e) => {
    if (notPastDate(e.target.value)) {
      setvalue(e.target.value);
    }
  };
  const handleState = () => {
    if (value !== "") handleDays(value);
    else showError();
  };
  return (
    <div className="my-3 p-3 border">
      <h2 className="text-center my-4 color-black">
        Please Enter Expected Shipping Date
      </h2>
      <div className="mt-3 d-flex align-items-center justify-content-between flex-wraped">
        <div className="w-100">
          <div
            className="mx-auto p-2 border border-radius-8 "
            style={{ maxWidth: "300px" }}
          >
            <TextField
              id="date"
              className="w-100 "
              label="Expected Date"
              type="date"
              name="date"
              onChange={handleDateChange}
              value={value || defaultValue}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <button
            className="background-primary color-white p-3 c-pointer border border-radius-8 d-block  mx-auto"
            onClick={() => handleState()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
