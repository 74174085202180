import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Header from "./components/Header/Header";
import Table from "./components/Table/Table";
import Pagination from "@material-ui/lab/Pagination";
const ProductsContainer = ({
  data,
  handlePagination,
  updateData,
  handleEditProduct,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [data]);
  return (
    <div>
      <Header updateData={updateData} />
      {loading ? (
        [...Array(5)].map((_, i) => (
          <Skeleton key={i} className="box-shadow-grey" height="200px" />
        ))
      ) : data?.products?.length > 0 ? (
        <>
          <Table
            handleEditProduct={handleEditProduct}
            products={data?.products}
          />
          <Pagination
            count={data?.pagination?.totalPages}
            size="large"
            page={data?.pagination?.currentPage}
            onChange={handlePagination}
          />
        </>
      ) : (
        <div style={{ marginTop: "100px" }}>
          <h1 className="text-center color-black">No products found</h1>
        </div>
      )}
    </div>
  );
};

export default ProductsContainer;
