import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
const NotFound = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 2000);
  }, []);
  return (
    <>
      <div className='NotFoundContainer'>
        <h3>Uh oh. Ever get that feeling like you're missing something?</h3>
        <div>
          <span>
            <p>
              Pardon us, we're having some trouble finding this page. It may be
              <br />
              because:
            </p>
            <ul>
              <li>The link or bookmark that took you here is outdated</li>
              <li>The page has moved to a different address</li>
              <li>The web address is typed incorrectly</li>
            </ul>
          </span>
        </div>
      </div>
    </>
  );
};

export default NotFound;
