import React from "react";
import style from "./Sidebar.module.css";
import * as icons from "../../../../Components/icons/Icons";
const sellerSidebar = [
  {
    title: "Dashboard",
    icon: icons.Home,
  },
  {
    title: "Orders",
    icon: icons.Orders,
  },
  {
    title: "Products",
    icon: icons.Cart,
  },
  {
    title: "Store Information",
    icon: icons.Info,
  },
  {
    title: "Analytics",
    icon: icons.OpenEnvelope,
  },
  {
    title: "Settings",
    icon: icons.Setting,
  },
];

const SideBarContainer = ({ selectedComp, changeTab, scope }) => {
  const sidebar = scope.length
    ? sellerSidebar.filter((sidebar) => scope.includes(sidebar.title))
    : sellerSidebar;

  return (
    <div className={style.tabLinksContainer}>
      {sidebar?.map((item) => (
        <div
          onClick={() => changeTab(item)}
          key={item?._id ?? item?.title}
          className={`${style.sideItem} ${
            selectedComp === item.title ? style.active : ""
          }`}
        >
          <div>
            <div className="myacc-link d-flex align-items-center">
              <span className="d-flex">
                <item.icon className={`${style.icon} svg-stroke`} />
              </span>
              <span
                id={style.tabLinkTxt}
                className="p-2 d-block w-100 white-space-nowrap"
              >
                {item.title}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SideBarContainer;
