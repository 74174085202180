import axios from "axios";
import { apiURL } from "../../config/default";

export const verifyToken = async (token) => {
  try {
    const res = await axios.get(`${apiURL}send-otp/${token}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const verifyOTP = async (otp) => {
  try {
    const res = await axios.post(`${apiURL}verify-otp`, { otp: otp });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
