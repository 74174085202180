import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import style from './otp.module.css';
//service
import { verifyOTP } from '../../../../services/otp/otp.services';
const inpStyle = {
  fontSize: '4rem',
};
const erStyle = {
  borderColor: '#c4113f',
};

const OTPpage = ({ otpSuccess }) => {
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);

  useEffect(() => {
    (async () => {
      if (otp.length == 6) {
        const res = await verifyOTP(otp);
        if (res) {
          otpSuccess(res.scope);
        } else {
          setOtpError(true);
        }
      }
    })();
  }, [otp.length]);

  return (
    <div>
      <div className="text-center  my-3">
        <strong id="logo">
          <img
            width="150px"
            className="object-fit-contain"
            src="https://sundialinsider.s3.us-east-2.amazonaws.com/setting/logo.svg"
            alt=""
          />
        </strong>
      </div>
      <h5 className="text-center my-3">
        Please Enter OTP sent to your registered email address
      </h5>

      <div className={style.otpContainer}>
        <OtpInput
          value={otp}
          onChange={(otp) => setOtp(otp)}
          numInputs={6}
          errorStyle={erStyle}
          inputStyle={inpStyle}
          shouldAutoFocus
          hasErrored={otpError}
          separator={<span>-</span>}
        />
      </div>
      <p
        style={{ width: '50%', margin: '0 auto' }}
        className="color-error text-center my-2"
      >
        {otpError && 'Please Enter Valid Code'}
      </p>
      <div
        className={`d-flex justify-content-between align-items-center ${style.resendContainer}`}
      >
        <p className={style.expMsg}>Your OTP will expire in 1 hour</p>
        <button>Resend OTP</button>
      </div>
    </div>
  );
};

export default OTPpage;
