import axios from 'axios';
import { apiURL } from '../../config/default';

export const getSellerOrders = async (page, filter) => {
  try {
    const { status, date } = filter;
    const ordersData = await axios.post(`${apiURL}seller-order?page=${page}`, {
      status,
      date,
    });
    return ordersData.data;
  } catch (err) {
    console.log(err);
  }
};

let cancelToken;
export const getSellerProducts = async (page, body) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = axios.CancelToken.source();
  try {
    const productsData = await axios.post(
      `${apiURL}search-products?page=${page}`,
      body,
      { cancelToken: cancelToken.token, silent: false }
    );
    return productsData.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateStatus = async (orderItemId, status) => {
  const res = await axios.post(`${apiURL}order/status-update`, {
    orderItemId,
    status,
  });
  return res;
};

export const createShipping = async (body) => {
  const res = await axios.post(`${apiURL}create-shiping`, body);
  return res;
};

export const addTrackingId = async (body) => {
  const res = await axios.post(`${apiURL}add-tracking-number`, body);

  return res;
};
export const getLabelBuffer = async (body) => {
  const res = await axios.post(`${apiURL}pdf-buffer`, body);

  return res;
};

export const getOrderDetails = async (id) => {
  const res = await axios.get(`${apiURL}view-order/${id}`);
  return res;
};

export const getGraphData = async () => {
  const res = await axios.get(`${apiURL}get-stats`);
  return res;
};

export const switchVendor = async (id) => {
  const res = await axios.post(`${apiURL}switchVendor`, { userId: id });
  return res;
};
