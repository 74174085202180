import { Link } from "react-router-dom";
import React from "react";

const LinkExpired = ({ toLogin }) => {
  return (
    <div>
      <div className='text-center p-3'>
        <strong id='logo'>
          <img
            width='150px'
            className='object-fit-contain'
            src='https://sundialinsider.s3.us-east-2.amazonaws.com/setting/logo.svg'
            alt=''
          />
        </strong>
      </div>
      <h5 className='text-center my-3'>
        Link is expired! click&nbsp;
        <Link className='underline-hovered' to='/login' onClick={toLogin}>
          here
        </Link>{" "}
        to login to check the order
      </h5>
    </div>
  );
};

export default LinkExpired;
