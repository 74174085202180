import React from "react";
import style from "./Loading.module.css";
const Loading = () => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,.3)",
        zIndex: "3",
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={style.loader}>
        <img src='/assets/images/loader.svg' width='120px' />
      </div>
    </div>
  );
};

export default Loading;
