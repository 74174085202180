import React from "react";
import { getSingleProduct } from "../../../../../../services/products/products.services";
const Table = ({ products, handleEditProduct }) => {
  const fetchProduct = async (id) => {
    const product = await getSingleProduct(id);
    handleEditProduct(product);
  };

  return (
    <div>
      <div className="p-3 mt-3 bg-white border-radius-8 box-shadow-grey">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className="text-center color-black  white-space-nowrap"
                  style={{ width: "16.66%" }}
                  scope="col"
                >
                  Name
                </th>
                <th
                  className="text-center color-black  white-space-nowrap"
                  style={{ width: "16.66%" }}
                  scope="col"
                >
                  Model Number
                </th>

                <th
                  className="text-center color-black  white-space-nowrap"
                  style={{ width: "16.66%" }}
                  scope="col"
                >
                  Availability
                </th>
                <th
                  className="text-center color-black  white-space-nowrap"
                  style={{ width: "16.66%" }}
                  scope="col"
                >
                  Stock
                </th>
                <th
                  className="text-center color-black  white-space-nowrap"
                  style={{ width: "16.66%" }}
                  scope="col"
                >
                  Views
                </th>
                <th
                  className="text-center color-black  white-space-nowrap"
                  style={{ width: "16.66%" }}
                  scope="col"
                >
                  Action
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div className="mt-3 border-radius-8">
        <div className="table-responsive">
          <div className="d-flex align-items-center flex-column justify-content-between w-100">
            {products?.map((product) => (
              <div
                className="f1 w-100 bg-white border-radius-8 my-2 box-shadow-grey overflow-x-auto"
                style={{ maxWidth: "98%" }}
              >
                <table class="table mb-0">
                  <tbody>
                    <tr>
                      <td style={{ width: "16.66%" }} className="text-center">
                        <p className="two-lines-text color-black" title="test">
                          {product.title}
                        </p>
                      </td>
                      <td
                        style={{ width: "16.66%" }}
                        className="text-center color-black white-space-nowrap"
                      >
                        {product.modelNumber}
                      </td>
                      <td
                        style={{ width: "16.66%" }}
                        className="text-center color-black  white-space-nowrap"
                      >
                        {product.quantity < 3 ? "Not Available" : "Available"}
                      </td>

                      <td
                        style={{ width: "16.66%" }}
                        className="text-center color-black  white-space-nowrap"
                      >
                        {product.quantity}
                      </td>
                      <td
                        style={{ width: "16.66%" }}
                        className="text-center color-black  white-space-nowrap"
                      >
                        {product.visited ?? 0}
                      </td>
                      <td
                        style={{ width: "16.66%" }}
                        className="text-center color-black  white-space-nowrap"
                      >
                        <button
                          onClick={() => fetchProduct(product?._id)}
                          className="background-primary border-radius-8 p-2 no-border c-pointer color-white"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="m-2 d-inline-block border-radius-8 p-2 box-shadow-grey">
                  <img
                    width="100%"
                    className="object-fit-contain"
                    width="100px"
                    height="100px"
                    src={product?.mainImage}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
