import React from "react";
import style from "./EditProduct.module.css";
import * as icons from "../../../../../../Components/icons/Icons";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { showSnackbar } from "../../../../../../redux/actions/snackBar";
import { useDispatch } from "react-redux";
import { submitEditedProduct } from "../../../../../../services/products/products.services";
const EditProduct = ({ product }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteAbleId, setDeleteAbleId] = useState("");
  const [data, setData] = useState({
    description: product?.product?.description,
    productId: product?.product?._id,
    images: product?.images,
  });
  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };
  const handleAddImage = async (e) => {
    const image = await convertImgToBase64(e.target.files[0]);
    setData({
      ...data,
      images: [
        ...data.images,
        {
          ftpUrl: image,
          doRemove: false,
        },
      ],
    });
  };
  const handleDeletePopup = (id) => {
    setOpen(true);
    setDeleteAbleId(id);
  };
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    setData({
      ...data,
      images: data.images.map((img, index) =>
        index === deleteAbleId ? { ...img, doRemove: true } : img
      ),
    });

    setOpen(false);
    dispatch(showSnackbar("Image Deleted", "error"));
  };

  const handleSubmit = () => {
    console.log(data);
    submitEditedProduct(data);
  };

  return (
    <div class="  border-radius-8">
      <div class="table-responsive box-shadow-grey border-radius-8">
        <div class="bg-white d-flex align-items-center justify-content-between w-100">
          <div class="f1 w-100">
            <table class="table">
              <thead>
                <tr>
                  <th className="text-center color-black" scope="col">
                    Name
                  </th>
                  <th className="text-center color-black" scope="col">
                    Model Number
                  </th>
                  <th className="text-center color-black" scope="col">
                    Images
                  </th>
                  <th className="text-center color-black" scope="col">
                    Avalability
                  </th>
                  <th className="text-center color-black" scope="col">
                    Quantity
                  </th>
                  <th className="text-center color-black" scope="col">
                    Viewes
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="text-center two-lines-text p-0 mx-auto color-black"
                    style={{ maxWidth: "140px" }}
                  >
                    {" "}
                    {product?.product?.title ?? ""}
                  </td>
                  <td className="text-center color-black">
                    {product?.product?.modelNumber}
                  </td>
                  <td className="text-center color-black">
                    {data?.images.filter((img) => !img.doRemove)?.length}
                  </td>
                  {console.log("this is a test", product?.product)}
                  <td className="text-center color-black">Not Available</td>
                  <td className="text-center color-black">
                    {product?.product?.quantity}
                  </td>
                  <td className="text-center color-black">
                    {product?.product?.visited}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-3 p-3 bg-white border-radius-8 box-shadow-grey mb-2">
        <div className="d-flex align-items-center justify-content-between">
          {" "}
          <div className="d-flex align-items-center">
            <h3 className="mx-2 font-weight-700 m-0 color-black">
              Product Videos/Images
            </h3>
            <p className="mb-0 color-light-grey">
              {data?.images.filter((img) => !img.doRemove)?.length} Images
            </p>
          </div>
          <div className="m-2 position-relative bg-ddd border border-info c-pointer border-radius-8 d-flex align-items-center">
            <label
              htmlFor="imgOrVideo"
              className="c-pointer p-2 m-0 text-center"
            >
              <strong title="Upload Image" className="color-black">
                + Add Image
              </strong>
            </label>
            <input
              type="file"
              className="d-none"
              name="imgOrVideo"
              id="imgOrVideo"
              onChange={handleAddImage}
            />
          </div>
        </div>
        <div className="d-flex align-items-center overflow-x-auto">
          {data?.images?.map(
            (img, index) =>
              !img.doRemove && (
                <div className={`${style.img} m-2 position-relative`}>
                  <div
                    className={`${style.dltIcon} c-pointer position-absolute bg-white rounded-circle d-flex align-items-center`}
                  >
                    <button
                      onClick={() => handleDeletePopup(index)}
                      className="c-pointer bg-transparent no-border"
                    >
                      {<icons.Delete className="m-2 z-index-1" />}
                    </button>
                  </div>
                  <div className="bg-ddd border-radius-8">
                    <img
                      className="object-fit-contain border-radius-8"
                      src={img?.ftpUrl ?? ""}
                      width="150px"
                      style={{ filter: "brightness(0.95)", height: "150px" }}
                    />
                  </div>
                </div>
              )
          )}
        </div>

        <div className="mt-3 p-3 bg-white border-radius-8">
          <div className="d-flex align-items-center">
            <h4 className="font-weight-700 color-black">Product Description</h4>
            <span className="mx-2 color-light-grey">(&gt; 200 Words)</span>
          </div>
          <div>
            <textarea
              className="w-100 bg-cream p-3 border-radius-8"
              name=""
              id=""
              value={data?.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              cols="30"
              rows="10"
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            className="c-pointer color-white no-border p-2 border-radius-8 background-primary"
          >
            <h5 className="m-0 d-inline-block">Update Product Information</h5>
          </button>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this image?"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditProduct;
