import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function OthersModal({ open, handleClose, handleSubmit }) {
  const [value, setVal] = useState("");
  const handleChange = (e) => {
    setVal(e.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Shipper</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add The Shipper Name.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            id="outlined_basic"
            label="Shipper Name"
            type="text"
            onChange={handleChange}
            value={value}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit(value)} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
