import React from "react";
import Modal from "@mui/material/Modal";

const modalStyle = {
  background: "#fff",
  maxWidth: "300px",
  margin: "auto",
  minHeight: "200px",
  marginTop: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  borderRadius: "20px",
  textAlign: "center",
  padding: "20px",
};

const LabelsModal = ({ labels, open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle}>
        <h5 className="text-center">Click on the image to download</h5>
        {labels?.map((label, i) => (
          <a className="d-inline-block m-2" download href={label?.image}>
            <img
              src={label?.image}
              width="50px"
              height="50px"
              style={{ borderRadius: "50%", border: "1px solid #000" }}
            />
            <span className="d-block">Label {i + 1}</span>
          </a>
        ))}
      </div>
    </Modal>
  );
};

export default LabelsModal;
