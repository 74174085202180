import React from 'react';
import Moment from 'react-moment';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const status_msg = {
  Available: { msg: 'item is available', color: '#3792FF' },
  pending: { msg: 'item is pending', color: 'rgb(255 55 240)' },
  'Back Order': { msg: 'item is backordered', color: '#7268CD' },
  Discontinued: { msg: 'item is discontinued', color: '#FF8E6B' },
  keep: { msg: 'user has decided to keep/wait for item', color: '#57a764' },
  replace: { msg: 'user has decided to replace item', color: '#ff6a00' },
  cancel: { msg: 'user has cancel this item', color: '#db1b50' },
  'Not Available': { msg: 'item is not available', color: '#db1b50' },
};

const RecentOrders = ({ data, handleSeeAll }) => {
  return (
    <div className="bg-white p-3 border-radius-8 h-100 box-shadow-grey">
      <div class="my-2 d-flex align-items-center justify-content-between">
        <h5 className="color-black">Recent Orders</h5>

        <button
          href="#"
          onClick={() => handleSeeAll('Orders')}
          className="bg-transparent c-pointer no-border color-black underlined underline-hovered"
        >
          See All
        </button>
      </div>
      <div className="table-responsive">
        <table class="table">
          <thead class="background-primary border-radius-8">
            <tr>
              <th
                scope="col"
                class="text-center color-white white-space-nowrap"
              >
                #Order Id
              </th>
              <th
                scope="col"
                class="text-center color-white white-space-nowrap"
              >
                Date
              </th>
              <th
                scope="col"
                class="text-center color-white white-space-nowrap"
              >
                Product Name
              </th>
              <th
                scope="col"
                class="text-center color-white white-space-nowrap"
              >
                Product Price
              </th>
              <th
                scope="col"
                class="text-center color-white white-space-nowrap"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.slice(0, 3).map((item, i) => (
              <tr class="border border-radius-8">
                <td class="align-middle color-light-grey text-center">
                  #{i + 1}
                </td>
                <td class="align-middle color-light-grey text-center">
                  <Moment format="MM/DD/YYYY">{item?.createdAt}</Moment>
                </td>
                <td class="align-middle color-light-grey text-center">
                  {item.title}
                </td>
                <td class="align-middle color-light-grey text-center">
                  ${Number(item.itemPrice).toFixed(2)}
                </td>
                <td class="align-middle color-light-grey text-center">
                  <div className="p-2 d-flex align-items-center justify-content-between border ml-2">
                    <span
                      className="orderStatus__dot5"
                      style={{
                        backgroundColor: status_msg?.[item.status]?.color,
                      }}
                    />
                    <span
                      title={status_msg[item.status].msg}
                      style={{ cursor: 'pointer' }}
                    >
                      {item.status}
                    </span>
                  </div>
                  {item.selectedOption && (
                    <>
                      <span>
                        <ArrowRightAltIcon />
                      </span>
                      <div
                        className="d-flex align-items-center ml-2"
                        title={status_msg?.[item?.selectedOption]?.msg}
                      >
                        <span
                          className="orderStatus__dot5"
                          style={{
                            backgroundColor:
                              status_msg[item.selectedOption]?.color,
                          }}
                        />
                        <span style={{ cursor: 'pointer' }}>
                          {item.selectedOption}
                        </span>
                      </div>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentOrders;
