import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { getGraphData } from "../../../../../../services/dashboard/dashboard.services";
const Graph = () => {
  const [graph, setGraph] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [2020, 2021, 2022, 2023, 2024, 2025],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 135, 50, 19, 260, 370, 91, 125],
      },
    ],
  });

  const getMonthName = (month) => {
    const date = new Date(null, month, null);
    const name = date.toLocaleString("default", { month: "long" });
    return name;
  };

  const getMonth = (arr) => {
    return arr.length
      ? arr?.map((item) => getMonthName(item.month))
      : ["jan", "fab", "mar"];
  };
  const getCounts = (arr) => {
    return arr.length ? arr?.map((item) => item.count) : [0, 0, 0];
  };
  useEffect(() => {
    (async () => {
      const res = await getGraphData();
      const months = getMonth(res.data);
      const counts = getCounts(res.data);

      setGraph({
        ...graph,
        options: {
          ...graph.options,
          xaxis: {
            categories: months,
          },
        },
        series: [{ ...graph.series, data: counts }],
      });
    })();
  }, []);

  return (
    <div className="bg-white p-2 border-radius-8 h-100 box-shadow-grey">
      <h2 className="color-black">Graph</h2>
      <Chart
        options={graph.options}
        series={graph.series}
        type="area"
        width="100%"
        height={320}
      />
    </div>
  );
};

export default Graph;
