import React, { useState, useEffect } from "react";
import style from "./Login.module.css";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import { login } from "../../../../redux/actions/auth";

const Login = ({ _auth, login, values, handleChange }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pinToken = urlParams.get("pinToken");
  const history = useHistory();
  useEffect(() => {
    if (!pinToken) {
      if (_auth.isAuth) {
        history.push("/"); 
      }
    }
  }, [_auth.isAuth]);

  const [showPass, setShowPass] = useState(false);

  // const [values, setValues] = useState({
  //   email: '',
  //   password: '',
  // });

  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;

  //   setValues({
  //     ...values,
  //     [name]: value,
  //   });
  // };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(values);
  };

  return (
    <div className="bg-cream p-3 h-100">
      <div className={style.container}>
        <h1 className="text-center">Login</h1>
        <form className={style.loginForm} onSubmit={handleSubmit}>
          <div className="my-12">
            <TextField
              name="email"
              className="w-100"
              id="outlined-basic"
              type="email"
              label="Email"
              required
              onChange={handleChange}
              value={values.email}
              variant="outlined"
            />
          </div>
          <FormControl className="w-100 " variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPass ? "text" : "password"}
              value={values.password}
              name="password"
              required
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>

          <div className="my-12">
            <button
              type="submit"
              // onClick={handleSubmit}
              className="no-border w-100 c-pointer my-12 border-radius-8 loginBtn"
            >
              Login
            </button>
          </div>
          {/* <div className="text-right">
            <small className="c-pointer underlined">Forgotten password?</small>
          </div> */}
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  _auth: state.auth,
});

export default connect(mapStateToProps, {
  login,
})(Login);
