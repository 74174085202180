import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { showSnackbar } from "../../redux/actions/snackBar";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const SnackBar = ({ message, type, $open }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState($open);

  useEffect(() => {
    setOpen($open);
  }, [$open]);

  const handleClose = (e) => {
    if (e?.target?.type) return;
    setOpen(false);
    dispatch(showSnackbar(null, "", false));
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  message: state.snackbar.error,
  type: state.snackbar.severity,
  $open: state.snackbar.open,
});

export default connect(mapStateToProps)(SnackBar);
