import React, { useEffect } from "react";
import DashboardContainer from "./pages/Dashboard/Dashboard.container/Dashboard.container";
import Header from "./Components/Header/Header";
import { auth, logout } from "./redux/actions/auth";
import LoginContainer from "./pages/Login/Login.container/Login.container";
import Loading from "./pages/Login/components/Loading/Loading";
import SnackBar from "./Components/snackBar/snackBar";
import NotFound from "./pages/NotFound";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoutes";

import { connect } from "react-redux";
function App(props) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pinToken = urlParams.get("pinToken");
  useEffect(() => {
    if (pinToken) props.logout();
    props.auth();
  }, []);

  // if (props.loading) return <Loading />;

  return (
    <Router>
      {props.loading && <Loading />}
      <SnackBar />
      <Header />
      <Switch>
        <Route exact path='/'>
          <ProtectedRoute Component={DashboardContainer} />
        </Route>
        <Route exact path='/login'>
          <Route
            render={(p) => {
              if (props.scope.length == 0) {
                return <LoginContainer {...p} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/",
                    }}
                  />
                );
              }
            }}
          />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  scope: state.auth.scope,
});

export default connect(mapStateToProps, {
  auth,
  logout,
})(App);
