import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Details from './components/order-details.component';
import Modal from "@mui/material/Modal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as stepsBody from "./components/StepsBody/StepBody";
import Steppers from "./components/Steps";
import {
  createShipping,
  addTrackingId,
  updateStatus,
  getOrderDetails,
  getLabelBuffer
} from "../../../../../../services/dashboard/dashboard.services";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { showSnackbar } from "../../../../../../redux/actions/snackBar";
import Moment from "react-moment";
import moment from "moment";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Check from "@material-ui/icons/Check";
import clsx from "clsx";
import LabelsModal from "./components/StepsBody/modals/LabelsModal";
import axios from 'axios'
import fileDownload from 'js-file-download'
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

function ManageOrders({ order_, requestComponent, refreshOrder }) {
  const [activeComp, setActiveComp] = useState(0);
  const [order, setorder] = useState({ ...JSON.parse(JSON.stringify(order_)) });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [discountinuedDialogOpen, setdiscountinuedDialogOpen] = useState(false);
  const [avaiableDate, setAvaiableDate] = useState("");
  const [managedBySundial, setManagedBySundial] = useState(
    order.status !== "pending"
  );
  const [downloadOption, setDownloadOption] = useState(false);
  const [labelsModalOpen, setLabelsModalOpen] = useState(false);

  const handleLabelModalClose = () => {
    setLabelsModalOpen(!labelsModalOpen);
  };

  useEffect(() => {
    if (!order.isSundialShipping) {
      if (order.shippingCompanyName) {
        setActiveComp(3);
      } else if (order.expected_shipping_date) {
        setActiveComp(2);
      } else if (order.status !== "pending") {
        setActiveComp(1);
      }
    }
  }, []);

  const updateOrder = async () => {
    const o = await refreshOrder(order);
    setorder(o);
  };

  const callForShippingDetails = () => {
    if (order?.status === "Available" && order?.isSundialShipping) {
      let keepCalling = setInterval(async () => {
        try {
          const res = await getOrderDetails(order?._id);
          if (res.data.orderItem?.shippingInfo?.labels?.length !== 0) {
            setDownloadOption(true);
            setorder(res.data.orderItem);
            clearInterval(keepCalling);
          }
        } catch (err) {
          clearInterval(keepCalling);
        }
      }, 5000);
    }
  };

  useEffect(() => {
    callForShippingDetails();
  }, [order?.status]);

  useEffect(() => {
    if (order?.shippingInfo?.labels?.length === 0) {
      callForShippingDetails();
    } else {
      setDownloadOption(true);
    }
  }, []);

  const dispatch = useDispatch();

  const setStatus = async (status) => {
    try {
      const res = await updateStatus(order._id, status);
      if (res.status === 200) {
        setorder((or) => ({
          ...or,
          status: status,
          trackingStatus: status === "Available" ? "Processing" : "",
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showError = (error = "Can't leave field empty") => {
    dispatch(showSnackbar(error, "warning"));
  };

  const handleStatus = async (status) => {
    switch (status) {
      case "Available":
        await setStatus(status);
        if (order.isSundialShipping) {
          setManagedBySundial(true);
        } else {
          handleChangeComponent(1);
        }
        break;

      case "Not Available":
        return await setStatus(status);

      case "Back Order":
        return setDialogOpen(true);

      case "Discontinued":
        setdiscountinuedDialogOpen(true);
        break;

      default:
        return status;
    }
  };

  const handleDiscountinuedOrder = async () => {
    await setStatus("Discontinued");
    if (order.isSundialShipping) {
      setManagedBySundial(true);
    } else {
      handleChangeComponent(1);
    }
    updateOrder();
  };

  const handleBackOrder = async () => {
    if (avaiableDate !== "") {
      await setStatus("Back Order");
      createShipping({
        orderItemId: order._id,
        availabilityDate: avaiableDate,
      });
      setorder({
        ...order,
        status: "Back Order",
        availabilityDate: avaiableDate,
      });
      setDialogOpen(false);
      if (order.isSundialShipping) {
        setManagedBySundial(true);
      } else {
        handleChangeComponent(1);
      }
    } else showError();
    updateOrder();
  };

  const handleExpectedDate = async (expectedDate) => {
    await createShipping({
      orderItemId: order._id,
      expected_shipping_date: moment(expectedDate).format("MM-DD-YYYY"),
    });

    setorder({ ...order, expected_shipping_date: expectedDate });
    handleChangeComponent(2);
  };

  const handleShipping = async (shipper) => {
    await createShipping({
      orderItemId: order._id,
      shippingCompanyName: shipper,
    });
    setorder({ ...order, shippingCompanyName: shipper });
    handleChangeComponent(3);
  };

  const handleTrackingNum = async (number) => {
    await addTrackingId({ orderItemId: order._id, trackingNumber: number });
    setorder({ ...order, TrackingNumber: number });
    updateOrder();
  };
  const notPastDate = (d) => {
    function convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    }
    let selectedDate = new Date(d);
    let today = convertUTCDateToLocalDate(new Date());

    if (selectedDate > today) return true;
    else {
      dispatch(showSnackbar("Can't select past dates", "warning"));
      return false;
    }
  };

  const stepBodyData = {
    status: (
      <stepsBody.StatusBtns
        status={order.status || ""}
        handleStatus={handleStatus}
        showError={showError}
      />
    ),
    shippingDate: (
      <stepsBody.ShippingDaysBtns
        defaultValue={order.expected_shipping_date || ""}
        handleDays={handleExpectedDate}
        notPastDate={notPastDate}
        showError={showError}
      />
    ),
    shipper: (
      <stepsBody.ShipperBtns
        handleShipping={handleShipping}
        selected={order.shippingCompanyName}
        showError={showError}
      />
    ),
    trackingNumber: (
      <stepsBody.TrackingBtns
        handleTrackingNum={handleTrackingNum}
        showError={showError}
      />
    ),
  };

  const handleAvailabilityDate = (d) => {
    if (notPastDate(d)) {
      setAvaiableDate(d);
    }
  };

  const handleDownloadLabels = () => {
    if (order.shippingInfo?.labels.length > 0) {
      order.shippingInfo?.labels.map((elem) => {
        setTimeout(() => {
          // var link = document.createElement('a');
          // link.href = elem.image;
          // link.download = 'file.pdf';
          // link.dispatchEvent(new MouseEvent('click'));
          downloadLabels(elem.image, `${elem.trackingNumber}.pdf`);
        }, 10000);
      });
    }
    const downloadLabels = async (url, filename) => {
      const res = await getLabelBuffer({ pdfLink: url });
      const base64Response = await fetch(
        `data:pdf;base64,${res.data.pdfBase64}`
      );
      const blob = await base64Response.blob();
      fileDownload(blob, filename);
      // await axios.get(url, {
      //   responseType: 'blob',
      // })
      // .then((res) => {
      // })
    };
  };
  const handleChangeComponent = (i) => {
    switch (i) {
      case 0:
        setActiveComp(0);
        break;
      case 1:
        setActiveComp(1);

        break;
      case 2:
        setActiveComp(2);

        break;
      case 3:
        setActiveComp(3);

        break;
      default:
        break;
    }
  };

  const steps = [
    "Order placed",
    "Processing",
    "Preparing To Ship",
    "Shipped",
    "Delivered",
  ];

  if (order.selectedOption === "Shipped") {
    return (
      <div className="d-flex bg-white">
        <div>
          <Details order={order} />
        </div>
        <div className="w-100 p-3 box-shadow-grey">
          <div className="d-flex align-items-center justify-content-between">
            <button
              type="button"
              className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
              onClick={() => requestComponent("order-listing", null)}
            >
              Go Back
            </button>
          </div>
          <Stepper
            className="p-0 mt-4"
            alternativeLabel
            activeStep={steps.findIndex((s) => s === order.trackingStatus) + 1}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <h3
            className="color-black text-center page-title"
            style={{ marginTop: 50 }}
          >
            Your order has been shipped.
          </h3>
        </div>
      </div>
    );
  }

  if (
    managedBySundial &&
    order.isSundialShipping &&
    order.status !== "cancel" &&
    (order.status !== "Back Order" ||
      (order.status === "Back Order" && order.selectedOption === "keep")) &&
    order.status !== "Discontinued" &&
    order.status !== "Not Available"
  )
    return (
      <div className="d-flex bg-white">
        <div>
          <Details order={order} />
        </div>
        <div className="w-100 p-3 box-shadow-grey">
          <div className="d-flex align-items-center justify-content-between">
            <button
              type="button"
              className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
              onClick={() => requestComponent("order-listing", null)}
            >
              Go Back
            </button>
          </div>

          <LabelsModal
            open={labelsModalOpen}
            handleClose={handleLabelModalClose}
            labels={order?.shippingInfo?.labels ?? []}
          />

          <Stepper
            className="p-0 mt-4"
            alternativeLabel
            activeStep={steps.findIndex((s) => s === order?.trackingStatus) + 1}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <h3
            className="color-black text-center page-title"
            style={{ marginTop: 50 }}
          >
            Your order shipment is being handled by Sundial, you will be kept
            updated via your registered email
          </h3>
          <div className="my-3 p-5 d-flex align-items-center justify-content-center">
            <div className="p-2 d-flex align-items-center">
              {!downloadOption ? (
                <div className="d-flex align-items-center border">
                  <img
                    src="/assets/images/loader.svg"
                    className="loader-image m-2"
                    width="20px"
                  />
                  <small className="px-2 color-primary">
                    Generating Shipping Labels.. Could take up to 30 seconds
                  </small>
                </div>
              ) : (
                <button
                  onClick={handleDownloadLabels}
                  style={{
                    maxWidth: "250px",
                    minWidth: "250px",
                    height: "60px",
                  }}
                  className="no-border p-2 c-pointer border-radius-8 color-white background-primary"
                >
                  <CloudDownloadIcon />{" "}
                  <p>
                    Download Shipping Label
                    {`${
                      order?.shippingInfo?.labels?.length === 1 ? "" : "s"
                    } (${order?.shippingInfo?.labels?.length})`}
                  </p>
                  {}
                </button>
              )}
            </div>

            <a
              download
              target="_blank"
              className="no-border p-2 c-pointer text-center border-radius-8 color-white background-primary"
              style={{
                maxWidth: "250px",
                minWidth: "250px",
                textDecoration: "none",
                height: "60px",
              }}
              href={order?.orderInvoice}
            >
              <CloudDownloadIcon />
              <p className="white-space-nowrap">
                Download Purchase Order Invoice
              </p>
            </a>
          </div>
        </div>
      </div>
    );

  if (order.status === "cancel")
    return (
      <div className="d-flex bg-white">
        <div>
          <Details order={order} />
        </div>
        <div className="w-100 p-3 box-shadow-grey">
          <button
            type="button"
            className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
            onClick={() => requestComponent("order-listing", null)}
          >
            Go Back
          </button>
          <h3 className="color-black text-center page-title">
            Your order has been Canceled
          </h3>
        </div>
      </div>
    );

  if (order.status === "Discontinued")
    return (
      <div className="d-flex bg-white">
        <div>
          <Details order={order} />
        </div>
        <div className="w-100 p-3 box-shadow-grey">
          <button
            type="button"
            className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
            onClick={() => requestComponent("order-listing", null)}
          >
            Go Back
          </button>
          <h3 className="color-black text-center page-title">
            This item has been Discontinued.
          </h3>
        </div>
      </div>
    );

  if (order.status === "Not Available")
    return (
      <div className="d-flex bg-white">
        <div>
          <Details order={order} />
        </div>
        <div className="w-100 p-3 box-shadow-grey">
          <button
            type="button"
            className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
            onClick={() => requestComponent("order-listing", null)}
          >
            Go Back
          </button>
          <h3 className="color-black text-center page-title">
            This item is not available
          </h3>
        </div>
      </div>
    );

  // backorder success message
  if (
    order.status === "Back Order" &&
    order.availabilityDate &&
    order.selectedOption !== "keep"
  )
    return (
      <div className="d-flex bg-white">
        <div>
          <Details order={order} />
        </div>
        <div className=" w-100 p-3 box-shadow-grey">
          <button
            type="button"
            className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
            onClick={() => requestComponent("order-listing", null)}
          >
            Go Back
          </button>
          <h3 className="text-center page-title color-black">
            Your order has been Back Ordered.
          </h3>
          <p
            style={{ textAlign: "center", margin: "10px 0", fontWeight: "500" }}
          >
            Waiting for customers response.....
          </p>
          <p className="text-center color-light-grey">
            Available Date is{" "}
            <Moment format="MM/DD/YYYY">{order.availabilityDate}</Moment>
            {/* <button
            className="ml-2 c-pointer p-2 no-border border border-radius-8 background-primary color-white"
            onClick={reOpenOrder}
          >
            Click here to re open Order
          </button> */}
          </p>
        </div>
      </div>
    );

  // avaiable success message follow up

  // const expectedDate = Array.from(order.expected_shipping_date);
  // let sliceTo;
  // sliceTo = expectedDate.indexOf("T");
  const calcExpectedDays = order.expected_shipping_date?.split("T")[0];

  if (
    order.shippingCompanyName &&
    order.status &&
    order.expected_shipping_date &&
    order.TrackingNumber
  )
    return (
      <div className="d-flex bg-white">
        <div>
          <Details order={order} />
        </div>
        <div className="w-100 p-3 box-shadow-grey">
          <button
            type="button"
            className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
            onClick={() => requestComponent("order-listing", null)}
          >
            Go Back
          </button>

          <Stepper
            className="p-0"
            alternativeLabel
            activeStep={steps.findIndex((s) => s === order.trackingStatus)}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <h3 className="color-black text-center page-title">
            Your order has been Confirmed 👍
          </h3>
          <p className="text-center color-light-grey">
            A Purchase Order has been emailed to{" "}
            <strong className="primary-color">customer</strong>
          </p>

          {/* <p> Status: {order.status}</p>
          <p> Order Number: {order?.itemOrderNumber}</p>
          <p> Shipping Name: {order.shippingCompanyName}</p>
          <p>
            {' '}
            Tracking Number:{' '}
            {order.TrackingNumber || 'Vendor is using own shipping'}
          </p>
          <p>
            {' '}
            Expected:{' '}
            <Moment format="MM/DD/YYYY">{order.expected_shipping_date}</Moment>
          </p> */}
        </div>
      </div>
    );

  return (
    <>
      <button
        type="button"
        className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
        onClick={() => requestComponent("order-listing", null)}
      >
        Go Back
      </button>

      <div className="d-flex bg-white">
        <div>
          <Details order={order} />
        </div>
        <div className="w-100 p-3 bg-white">
          <Steppers
            activeStep={activeComp}
            handleChangeComponent={handleChangeComponent}
            render={() => stepBodyData[Object.keys(stepBodyData)[activeComp]]}
          />
        </div>

        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Please Select Availability Date
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                id="datetime-local"
                label="Availability Date"
                type="date"
                className="w-100"
                value={avaiableDate}
                onChange={(e) => handleAvailabilityDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleBackOrder} color="primary" autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={discountinuedDialogOpen}
          onClose={() => discountinuedDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to continue!
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              onClick={() => setdiscountinuedDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDiscountinuedOrder}
              color="primary"
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {order.status === "Back Order" && (
        <div className="w-100 p-3 box-shadow-grey">
          <h3 className="text-center page-title color-black">
            This Order was backed and the customer chose to wait
          </h3>
          <p className="text-center color-light-grey">
            Avaiable Date is{" "}
            <Moment format="MM/DD/YYYY">{order.availabilityDate}</Moment>
          </p>
        </div>
      )}
    </>
  );
}

export default ManageOrders;
