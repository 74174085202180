import React, { useState } from "react";
import TextField from "@material-ui/core/TextField"; 

const Header = ({ updateData }) => {
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    setValue(e.target.value);
    updateData(e.target.value);
  };

  const clearField = () => {
    setValue("");
    updateData("");
  };

  return (
    <div>
      <div className="p-3 bg-white d-flex justify-content-between flex-wrap">
        <h3 className="page-title color-black">My Products</h3>
        <div>
          <TextField
            id="outlined-basic"
            label="Search"
            type="search"
            variant="outlined"
            value={value}
            className="myProductsSearchBar"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
