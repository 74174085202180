import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 24,
    left: 'calc(-50% + -4px)',
    right: 'calc(50% + 16px)',
    zIndex: 0,
  },
  active: {
    '& $line': {
      borderColor: `var(--primary-color)`,
    },
  },
  completed: {
    '& $line': {
      borderColor: `var(--primary-color)`,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function OrderFlowSteps(props) {
  const { active, completed, handleChangeComponent, label } = props;

  return (
    <div>
      {completed ? (
        <button className="position-relative z-index-1 color-white background-secondary border-radius-8 p-3 no-border c-pointer">
          {label}
        </button>
      ) : active ? (
        <button className="position-relative z-index-1 color-white background-primary border-radius-8 p-3 no-border c-pointer">
          {label}
        </button>
      ) : (
        <button
          className="position-relative z-index-1  border  border-radius-8 p-3 no-border c-pointer"
          disabled
          style={{ cursor: 'not-allowed', backgroundColor: '#ddd' }}
        >
          {label}
        </button>
      )}{' '}
    </div>
  );
}

function getSteps() {
  return ['Status', 'Shipping Date', 'Shipper', 'Tracking#'];
}

export default function Steppers({
  activeStep,
  handleChangeComponent,
  render,
}) {
  const steps = getSteps();

  return (
    <div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <OrderFlowSteps
                  {...props}
                  label={label}
                  handleChangeComponent={() => handleChangeComponent(index)}
                />
              )}
            />
          </Step>
        ))}
      </Stepper>

      <div style={{ minHeight: '300px' }}>
        <div>
          <Typography>{render()}</Typography>
          {/* <div className="d-flex justify-content-between my-3">
            <button
              style={{
                height: "40px",
                width: "40px",
              }}
              className={`c-pointer  border-radius-50 p-2 bg-transparent ${
                activeStep === 0 ? "border" : "border-dark"
              }`}
              disabled={activeStep === 0}
              onClick={() => handleChangeComponent(activeStep - 1)}
            >
              <ArrowBackIcon />
            </button>
            <button
              style={{
                height: "40px",
                width: "40px",
              }}
              className="c-pointer border-dark border-radius-50 p-2 bg-transparent"
              variant="contained"
              color="primary"
              onClick={() =>
                handleChangeComponent(activeStep !== 4 && activeStep + 1)
              }
            >
              <ArrowForwardIcon />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
