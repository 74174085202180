import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import style from './StoreInformation.module.css';
import Select from '../../../../Components/Select/Select';

const countries = ['USA', 'Russia', 'Canada', 'China'];
const cities = ['New York', 'Chicago', 'Houston', 'Philadelphia'];
const states = ['California', 'Ohio', 'Alabama', 'Connecticut'];
const StoreInformation = () => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    country: countries[0],
    city: cities[2],
    state: states[2],
    address: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSelectChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div
      className="p-3 bg-white border-radius-8 box-shadow-grey mb-2"
      style={{ height: "97%" }}
    >
      <form id={style.storeForm} onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center flex-wrap-small">
          <div className="m-2 w-100">
            <TextField
              className={`${style.input} w-100 myProductsSearchBar`}
              id="outlined-basic"
              label="Name"
              value={values.name}
              onChange={handleSelectChange}
              name="name"
              variant="outlined"
            />
          </div>
          <div className="m-2 w-100">
            <TextField
              className={`${style.input} w-100 myProductsSearchBar`}
              id="outlined-basic"
              label="Email"
              name="email"
              value={values.email}
              onChange={handleSelectChange}
              variant="outlined"
            />
          </div>
          <div className="m-2 w-100">
            <TextField
              className={`${style.input} w-100 myProductsSearchBar`}
              id="outlined-basic"
              label="Phone"
              type="number"
              name="phone"
              value={values.phone}
              onChange={handleSelectChange}
              variant="outlined"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap-small">
          <div className="m-2 w-100">
            <Select
              options={countries}
              title="Select Country"
              name="country"
              value={values.country}
              handleSelectChange={handleSelectChange}
            />
          </div>
          <div className="m-2 w-100">
            <Select
              options={countries}
              title="Select City"
              name="city"
              value={values.city}
              handleSelectChange={handleSelectChange}
            />
          </div>
          <div className="m-2 w-100">
            <Select
              options={countries}
              title="Select Country"
              name="state"
              value={values.state}
              handleSelectChange={handleSelectChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="m-2 w-100">
            <TextField
              className={`${style.input} w-100 myProductsSearchBar`}
              id="outlined-basic"
              label="Address"
              name="address"
              value={values.address}
              onChange={handleSelectChange}
              variant="outlined"
            />
          </div>
        </div>
        <div>
          <button className="m-2 btn no-border c-pointer background-primary px-3 p-2 border-radius-8 color-white">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default StoreInformation;
