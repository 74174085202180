import React from "react";
import SellerHeader from "./components/SellerHeader/SellerHeader";
import RecentOrders from "./components/RecentOrders/RecentOrders";
import StoreInformation from "./components/StoreInformation/StoreInformation";
import Graph from "./components/Graph/Graph";
import Products from "./components/Products/Products";
import style from "./Dashboard.module.css";
const Dashboard = ({
  ordersData,
  productsData,
  handleEdit,
  handleSeeAllProducts,
  handleSeeAll,
}) => {
  return (
    <div>
      <SellerHeader />
      <div className={style.containerBody}>
        <div className={style.contentContainer}>
          <div className={style.col8}>
            <RecentOrders
              handleSeeAll={handleSeeAll}
              data={ordersData?.orderItems}
            />
          </div>{" "}
          <div className={style.col4}>
            <StoreInformation handleEdit={handleEdit} />
          </div>
        </div>
        <div className={style.contentContainer}>
          <div className={style.col8}>
            <Graph />
          </div>{" "}
          <div className={style.col4}>
            <Products
              handleSeeAllProducts={handleSeeAllProducts}
              data={productsData?.products}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
