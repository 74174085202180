import axios from 'axios';
import { apiURL } from '../../config/default';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_SUCCESS,
  AUTH_FAIL,
  LOGOUT_SUCCESS,
  SET_SCOPE,
  LOADING,
  WAITING,
  ADD_CALL,
  REMOVE_CALL,
} from '../types';
import { alert } from './alert';
export const login =
  ({ email, password }) =>
  async (dispatch) => {
    const _config = {
      headers: {
        'Content-Type' : 'application/json',
      },
    };

    const body = JSON.stringify({
      email,
      password,
    });

    try {
      dispatch({
        type: LOADING,
      });
      const res = await axios.post(`${apiURL}login`, body, _config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(auth());
      return res;
    } catch (err) {
      const errors = err?.response?.data?.error;

      // if (errors) {
      //   errors.forEach((error) => {
      //     dispatch(
      //       alert(error.msg, "danger", {
      //         marginTop: "3em",
      //         marginBottom: "2em",
      //       })
      //     );
      //   });
      // }

      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

export const auth = () => async (dispatch) => {
  const token = process.browser && localStorage?.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
  }

  try {
    const res = await axios.get(`${apiURL}whoAmI`);
    dispatch({
      type: AUTH_SUCCESS,
      payload: res.data,
    });
    process.browser && localStorage?.setItem('user', JSON.stringify(res.data));

    return res;
  } catch (err) {
    dispatch({
      type: AUTH_FAIL,
    });
  }
};

export const logout = () => async (dispatch) => {
  process.browser && localStorage?.removeItem('user');
  delete axios.defaults.headers.common['Authorization'];
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const setScope = (scope) => (dispatch) => {
  dispatch({
    type: SET_SCOPE,
    payload: scope,
  });
};

export const setWaiting = (bool) => (dispatch) => {
  dispatch({
    type: WAITING,
    payload: bool,
  });
};

export const addToStack = (call) => (dispatch) => {
  dispatch({
    type: ADD_CALL,
    payload: call,
  });
};

export const removeFromStack = (call) => (dispatch) => {
  dispatch({
    type: REMOVE_CALL,
    payload: call,
  });
};
