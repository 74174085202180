/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import Axios from 'axios';
import CryptoJS from 'crypto-js';
import { showSnackbar } from '../../redux/actions/snackBar';
import {
  setWaiting,
  addToStack,
  removeFromStack,
} from '../../redux/actions/auth';
import { apiURL } from '../../config/default';
import { CallMerge } from '@material-ui/icons';

/* Error Show/Hide logic */
const handleError = (store, err = null) => {
  if (err) {
    if (
      err?.config?.url.includes('api/login') &&
      window?.location.pathname.includes('login')
    ) {
      store.dispatch(
        showSnackbar(
          !err?.message ? 'Incorrect username or password' : err?.message,
          'error'
        )
      );
    } else if (
      err?.config?.url.includes(apiURL) &&
      !err?.config?.url.includes('wishlist') &&
      !err?.config?.url.includes('auth') &&
      !window?.location.pathname.includes('login')
    ) {
      store.dispatch(showSnackbar(err?.message, 'error'));
    }
  }
};

/* Function to validate|prepare|modify error object */
const prepareErrorObject = (error) => {
  let err = (error?.response?.data ?? error) || new Error('Network Error');
  if (typeof err === 'object') {
    err.timestamp = Date.now();
    err.config = error?.config;
  } else {
    err = {};
    err.message = 'Something went wrong.';
    err.timestamp = Date.now();
  }
  return err;
};

export default {
  setupInterceptors: (store) => {
    let token = store.getState((state) => state).auth.token;
    Axios.interceptors.request.use(
      (config) => {
        const { silent = true } = config;

        if (silent) {
          store.dispatch(addToStack('call'));
        }

        // if (
        //   config?.data &&
        //   !config?.data?.encryption &&
        //   process.env.REACT_APP_ENVIROMENT !== 'DEV'
        // ) {
        //   const encrypted = CryptoJS.AES.encrypt(
        //     typeof config.data === 'string'
        //       ? config.data
        //       : JSON.stringify(config.data),
        //     '1EfSwhjetueGsu6ow07Oq2axDRwhRuZI'
        //   );

        //   config.data = { encryption: encrypted.toString() };
        // }

        return config;
      },
      (error) => {
        console.log('err', { error });
        handleError(store, error);
        // return Promise.reject(error ? error["response"] : null);
      }
    );

    // Response interceptor
    Axios.interceptors.response.use(
      (response) => {
        const { data = {} } = response;
        const { silent = true } = response.config;
        if (data.status >= 400) {
          const err = prepareErrorObject(data);
          handleError(store, err);
        }

        if (silent) {
          store.dispatch(removeFromStack('call'));
        }
        if (data?.encryption && process.env.REACT_APP_ENVIROMENT !== 'DEV') {
          const bytes = CryptoJS.AES.decrypt(
            data.encryption,
            '1EfSwhjetueGsu6ow07Oq2axDRwhRuZI'
          );
          const body = JSON.parse(bytes.toString(CryptoJS.enc.Utf8) || {});
          response.data = body;
        }
        return response;
      },
      (error) => {
        const err = prepareErrorObject(error);
        handleError(store, err);
        const { silent = true } = error.config;
        if (silent) {
          store.dispatch(removeFromStack('call'));
        }

        return Promise.reject(error ? error['response'] : null);
      }
    );
  },
};
