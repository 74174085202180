import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Moment from 'react-moment';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
const status_msg = {
  Available: { msg: 'item is available', color: '#3792FF' },
  pending: { msg: 'item is pending', color: '#3792FF' },
  'Back Order': { msg: 'item is backordered', color: '#7268CD' },
  Discontinued: { msg: 'item is discontinued', color: '#FF8E6B' },
  keep: { msg: 'user has decided to keep/wait for item', color: '#57a764' },
  replace: { msg: 'user has decided to replace item', color: '#ff6a00' },
  cancel: { msg: 'user has cancel this item', color: '#db1b50' },
  Shipped: { msg: 'order has been shipped', color: '#3792FF' },
  Processing: { msg: 'order has been shipped', color: '#7268CD' },
};

const selectStyle = {
  backgroundColor: "#f4f4f5",

  marginRight: "50px",
  // border: "1px solid #ddd",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const datePickerStyle = {
  color: "var(--primary-color)",
  backgroundColor: "#f4f4f5",
  // borderBottom: "1px solid var(--primary-color)",
};
const colorPrimary = {
  color: "var(--primary-color)",
};

function OrderListing({
  data = {},
  handlePagination,
  requestComponent,
  filter,
  handleFilter,
}) {
  const { orderItems = [], pagination = {} } = data;
  const statuses = ["Available", "Back Order", "Discontinued", "pending"];
  const [state, setState] = useState(filter);
  useEffect(() => {
    handleFilter(state);
  }, [state]);
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <>
      {/* {!orderItems.length && (
        <h3 className='color-black text-center'>No orders found!</h3>
      )} */}
      {
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl style={{ alignSelf: "end" }}>
            <Select
              native
              value={state.status}
              onChange={handleChange}
              style={selectStyle}
              className="color-black"
              inputProps={{
                name: "status",
                id: "order-status",
              }}
            >
              <option style={{ color: "#000" }} aria-label="None" value="">
                All
              </option>
              {statuses.map((s) => (
                <option style={{ color: "#000" }} value={s}>
                  {s}
                </option>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="datetime-local"
            label="Date"
            type="date"
            name="date"
            style={datePickerStyle}
            value={state.date}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              name: "date",
            }}
            // placeholder="MM/DD/YY"
            InputProps={
              state.date != "" && {
                endAdornment: (
                  <ClearIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setState({ ...state, date: "" })}
                  />
                ),
              }
            }
          />
        </div>
      }
      {orderItems?.map((item) => (
        <div className="bg-white border-radius-8 p-3 mt-3 box-shadow-grey">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div
                className="border border-radius-8"
                style={{ width: "152px" }}
              >
                <img src={item.mainImage} width="150px" height="150px" alt="" />
              </div>
              <div className="ml-3">
                <div>
                  <h4 className="color-black my-12">{item.title}</h4>
                </div>
                <div>
                  <span className="color-light-grey">
                    Date: <Moment format="MM/DD/YYYY">{item?.createdAt}</Moment>
                  </span>
                </div>
                <div>
                  <span className="color-light-grey">
                    Quantity: {item.quantity}
                  </span>
                </div>
                <div>
                  <span className="color-light-grey">
                    Order Number: {item?.itemOrderNumber}
                  </span>
                </div>
                <div>
                  <span className="color-light-grey">
                    Product price: ${Number(item.itemPrice).toFixed(2)}
                  </span>
                </div>
                <div
                  className="p-2 mt-2  d-flex align-items-center  border cancel_recent5_ab_parent"
                  style={{
                    width: "fit-content",
                    gap: "0 15px",
                  }}
                >
                  <div className="d-flex align-items-center ml-2">
                    <span
                      className="orderStatus__dot5"
                      style={{
                        backgroundColor: status_msg[item.status]?.color,
                      }}
                    />
                    <span
                      className="c-pointer color-black"
                      title={status_msg[item.status]?.msg}
                    >
                      {item.status}
                    </span>
                  </div>
                  {item.selectedOption && (
                    <>
                      <span>
                        <ArrowRightAltIcon style={{ color: "grey" }} />
                      </span>
                      <div
                        className="d-flex align-items-center ml-2"
                        title={status_msg[item.selectedOption]?.msg}
                      >
                        <span
                          className="orderStatus__dot5"
                          style={{
                            backgroundColor:
                              status_msg[item.selectedOption]?.color,
                          }}
                        />
                        <span className="c-pointer color-black">
                          {item.selectedOption}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="align-self-end">
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => requestComponent("manage-order", item)}
                  className="no-border p-3 c-pointer border-radius-50vw m-2 color-white background-primary"
                >
                  Manage Order
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Pagination
        count={pagination.totalPages}
        size="large"
        page={pagination.currentPage}
        onChange={handlePagination}
      />
    </>
  );
}

export default OrderListing;
