import React, { useState } from 'react';
import { getOrderDetails } from '../../../../services/dashboard/dashboard.services';
import ManageOrders from './components/manage-orders/manage-orders';
import OrderListing from './components/order-listing/order-listing.component';
const Orders = ({ data, handlePagination, refresh, filter, handleFilter }) => {
  const [activeComponent, setactiveComponent] = useState('order-listing');
  const [order, setorder] = useState({});
  const comp = {
    "order-listing": () => (
      <OrderListing
        data={data}
        handlePagination={handlePagination}
        requestComponent={requestComponent}
        filter={filter}
        handleFilter={handleFilter}
      />
    ),
    "manage-order": () => (
      <ManageOrders
        order_={order}
        requestComponent={requestComponent}
        refreshOrder={refreshOrder}
      />
    ),
  };

  const refreshOrder = async (data) => {
    const res = await getOrderDetails(data._id);
    setorder(res.data.orderItem);
    return res.data.orderItem;
  };

  const requestComponent = async (selector, data) => {
    if (selector && data) {
      if (selector === "manage-order") {
        const res = await getOrderDetails(data._id);
        setorder(res.data.orderItem);
      }

      // setselectedData(data);
    }
    if (selector === "order-listing") {
      refresh();
    }
    setactiveComponent(selector);
  };

  const Component = comp[activeComponent];



  return (
    <div className="border-radius-8 h-100">
      <Component />
    </div>
  );
};

export default Orders;
