export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// Auth
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

// Logout
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Alert
export const SHOW_ALERT = "SHOW_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//Scope

export const SET_SCOPE = "SET_SCOPE";

// snackbar
export const SNACKBAR = "SNACKBAR";
export const WAITING = "WAITING";

export const LOADING = "LOADING";
export const ADD_CALL = "ADD_CALL";
export const REMOVE_CALL = "REMOVE_CALL";
