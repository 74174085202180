let apiURL = "https://order.sundialhome.com/api/";

if (process.env.REACT_APP_ENVIROMENT === "DEV") {
  //tahir's ip
  // apiURL = "http://192.168.18.5:3001/api/";
  //fallback heroku
  // apiURL = "https://newsundial.herokuapp.com/api/";
  //Muhammad Asif
  // apiURL = "http://192.168.18.111:3000/api/";
  //Usman
  // apiURL = 'http://localhost:5000/api/';
  //live Url
  // apiURL = "https://api.sundialhome.com/api/";
  //ameer hamza
  // apiURL = "http://192.168.18.125:3001/api/";
  //qa env
  apiURL = "https://qavendorapi.sundialhome.com/api/"
  // DEVELOPMENT
  // apiURL = "http://192.168.18.126:3001/api/";
}

console.log("current Enviroment =>", apiURL);

export { apiURL };
