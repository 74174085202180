import React from "react";

const Products = ({ data, handleSeeAllProducts }) => {
  return (
    <div className="bg-white border-radius-8 p-2 h-100 box-shadow-grey">
      <div className="my-12">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="color-black">Product Listing</h4>
          <button
            href="#"
            onClick={handleSeeAllProducts}
            className="bg-transparent c-pointer no-border color-black underlined underline-hovered"
          >
            See All
          </button>
        </div>
      </div>
      {data?.slice(0, 3).map((item) => (
        <div class="my-12 p-2 border border-radius-8 d-flex  flex-wrap justify-content-between">
          <div class="d-flex">
            <div class="d-flex align-items-center">
              <img
                src={item.mainImage}
                // srcset="https://sundialinsider.s3.us-east-2.amazonaws.com/moe%27s/866-GO-1004-29.jpg"
                width="40px"
                height="40px"
                effect="blur"
                className="border-radius-8"
                alt="the image says ZAYDEN DINING CHAIR DARK GREY"
              />
            </div>
            <div className="m-2" style={{ maxWidth: "150px" }}>
              <p
                className="color-black mb-0 two-lines-text"
                title="ZAYDEN DINING CHAIR DARK GREY"
              >
                {item.title}
              </p>
              <small className="d-block color-light-grey">
                {item.modelNumber}
              </small>
            </div>
          </div>
          <div style={{ minWidth: "55px", textAlign: "right" }}>
            <strong className="color-black">
              ${Number(item.price).toFixed(2)}
            </strong>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products;
