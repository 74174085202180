import React from 'react';
import Moment from 'react-moment';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

function Details({ order }) {
  return (
    <div
      className="bg-white border-radius-8 p-3"
      style={{ borderRight: "1px solid #ddd" }}
    >
      <div className="d-flex">
        <div className="p-2 border-right" style={{ maxWidth: "252px" }}>
          <div className="text-center">
            <img
              src={order?.mainImage}
              width="100%"
              height="auto"
              effect="blur"
              className="border-radius-8"
              alt="product"
            />
          </div>
          <div className="ml-3">
            <div>
              <h4 className="color-black">{order?.title}</h4>
            </div>
            <div>
              <span className="color-primary">
                Date:&nbsp;
                <Moment format="MM/DD/YYYY">{order?.createdAt}</Moment>
              </span>
            </div>
            <div>
              <span className="color-primary">
                Quantity:&nbsp;{order?.quantity}
              </span>
            </div>
            <div>
              <p className="color-primary">Order Number:</p>
              <span className="white-space-nowrap color-primary">
                &nbsp;{order?.itemOrderNumber}
              </span>
            </div>
            <div>
              <span className="color-primary">
                Price:&nbsp;${parseFloat(order?.itemPrice).toFixed(2)}
              </span>
            </div>
            {console.log(order)}
            <div
              style={{ maxWidth: "150px" }}
              className={`mt-2 px-2 py-1 d-flex align-items-center justify-content-betwee border ${order?.status}_recent5_ab_parent`}
            >
              <span
                className={`${order?.status
                  .split(" ")
                  .join("")}_recent5_ab orderStatus__dot5`}
              ></span>
              <span
                className="color-black
               ml-2"
              >
                {order?.status}
              </span>
            </div>
            {order?.expected_shipping_date && (
              <div>
                <span className="color-primary">
                  Availability Date:{" "}
                  <Moment format="MM/DD/YYYY">
                    {order?.expected_shipping_date}
                  </Moment>
                </span>
              </div>
            )}
            {order?.shippingCompanyName && (
              <div>
                <span className="color-primary">
                  Shipping Company: {order?.shippingCompanyName}
                </span>
              </div>
            )}
            {order?.TrackingNumber && (
              <div>
                <span
                  className="color-primary"
                  style={{ wordBreak: "break-word" }}
                >
                  Tracking Number: {order?.TrackingNumber}
                </span>
              </div>
            )}
            {
              !order?.isSundialShipping && order?.orderInvoice && (  <a  href={order?.orderInvoice} download
                target="_blank"
                style={{ maxWidth: "150px",  textDecoration: "none"}}
                className={`mt-2 px-2 py-1 d-flex align-items-center justify-content-betwee     no-border p-2 c-pointer text-center color-white background-primary`}
              >
                
                <span
                  className="color-black white-space-nowrap ml-3"
                  style={{color: '#fff',textAlign:"center",}}
                >
                  <CloudDownloadIcon fontSize='small'/> Invoice 
                </span>
              </a>       
         
        

              ) 
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
