import React from "react";
import { useSelector } from "react-redux";

const StoreInformation = ({ handleEdit }) => {
  const data = useSelector((state) => state.auth.user);
  return (
    <div className="p-2 bg-white h-100 border-radius-8 box-shadow-grey">
      <div className="d-flex justify-content-between">
        <h3 className="color-black">Store Information</h3>
        <button
          href="#"
          onClick={handleEdit}
          className="bg-transparent c-pointer no-border color-black underlined underline-hovered"
        >
          Edit Info
        </button>
      </div>
      <div className="my-2">
        {data?.user && data?.profile && (
          <>
            <h4 className="color-black m-2 word-break-word font-weight-700">
              {data.user?.fullName}
            </h4>
            <h4 className="color-black m-2 word-break-word">
              {data.user?.email}
            </h4>
            <h4 className="color-black m-2 word-break-word">
              <span>Post Code: </span>
              {data.profile?.storePostcode}
            </h4>
            <h4 className="color-black m-2 word-break-word">
              <span>Store Address:&nbsp; </span>
              {data.profile?.storeAddress}
            </h4>
          </>
        )}
      </div>
    </div>
  );
};

export default StoreInformation;
