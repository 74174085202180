import { connect, useSelector } from "react-redux";
import { auth } from "../../../redux/actions/auth";
import React, { useEffect, useState, useMemo } from "react";
import SideBarContainer from "../components/Sidebar/SideBar.container";
import Dashboard from "../components/Dashboard/Dashboard";
import Orders from "../components/Orders/Orders";
import ProductsContainer from "../components/Products/Products.container";
import style from "./Dashboard.module.css";
import EditProduct from "../components/Products/components/EditProduct/EditProduct";
import ManageOrders from "../components/Orders/components/manage-orders/manage-orders";
import StoreInformation from "../components/StoreInformation/StoreInformation";
import Loading from "../components/Loading/Loading";
import Badge from "@mui/material/Badge";

//services

import {
  getSellerOrders,
  switchVendor,
  getSellerProducts,
} from "../../../services/dashboard/dashboard.services";

const DashboardContainer = ({ isWaiting, callStack, ...props }) => {
  const [selectedComp, setSelectedComp] = useState("Dashboard");
  const [ordersData, setOrdersData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [orderPage, setOrderPage] = useState(1);
  const [ProductPage, setProductPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [ordersFilter, setOrdersFilter] = useState({ status: "", date: "" });
  const [editableProduct, setEditableProduct] = useState({});
  const [filteredVendors, setFilteredVendors] = useState([]);
  const scope = useSelector((state) => state.auth.scope);
  const state = useSelector((state) => state);
  const inScope = (s) => {
    // return scope.filter((sco) => s.toLowerCase() == sco.toLowerCase()).length ==
    //   0 || scope.length == 0
    //   ? false
    //   : true;
    return true;
  };

  useEffect(() => {
    (async () => {
      const ordersData = await getSellerOrders(orderPage, ordersFilter);
      setOrdersData(ordersData);
    })();
  }, [orderPage, ordersFilter]);

  useEffect(() => {
    if (scope.length) setSelectedComp(scope[0]);
  }, [scope]);

  useEffect(() => {
    (async () => {
      const productsData = await getSellerProducts(ProductPage, {
        searchTerm: searchQuery,
        sortBy: "A-Z",
      });
      setProductsData(productsData);
    })();
  }, [ProductPage, searchQuery]);

  const handleOrderPagination = (event, value) => {
    setOrderPage(value);
  };
  const handleProductPagination = (event, value) => {
    setProductPage(value);
  };

  const changeTab = (item) => {
    const title = item.title.split(" ").join("");

    setSelectedComp(title);
    setOrderPage(1);
    setProductPage(1);
    setSearchQuery("");
  };
  const handleRefreshOrdersData = async () => {
    const ordersData = await getSellerOrders(orderPage, ordersFilter);
    setOrdersData(ordersData);
  };

  const handleEditProduct = (product) => {
    setEditableProduct(product);

    setSelectedComp("EditProduct");
  };

  const handleEdit = () => {
    setSelectedComp("StoreInformation");
  };
  const handleSeeAllProducts = () => {
    setSelectedComp("Products");
  };
  const changeComp = (title) => {
    setSelectedComp(title);
  };
  const components = useMemo(
    () => ({
      Dashboard: (
        <Dashboard
          handleEdit={handleEdit}
          ordersData={ordersData}
          handleSeeAll={changeComp}
          productsData={productsData}
          handleSeeAllProducts={handleSeeAllProducts}
        />
      ),
      Orders: (
        <Orders
          data={ordersData}
          handlePagination={handleOrderPagination}
          refresh={handleRefreshOrdersData}
          filter={ordersFilter}
          handleFilter={setOrdersFilter}
        />
      ),
      Products: (
        <ProductsContainer
          handleEditProduct={handleEditProduct}
          data={productsData}
          handlePagination={handleProductPagination}
          updateData={(data) => setSearchQuery(data)}
        />
      ),
      EditProduct: <EditProduct product={editableProduct} />,
      ManageOrders: <ManageOrders />,
      StoreInformation: <StoreInformation />,
    }),
    [ordersData, productsData, editableProduct]
  );
  const selectedId = state.auth?.user?.user?._id;

  const handleVendorChange = async (id) => {
    const token = await switchVendor(id);

    localStorage.setItem("token", token.data.token);

    window.location.reload();
  };
  return (
    <div className={style.container}>
      {callStack.length > 0 && <Loading />}

      <div className={style.float}>
        {state.auth?.vendors?.map((vendor) => {
          if (vendor?._id === state?.auth?.user?.user._id) {
            return (
              <div
                style={{
                  border: selectedId === vendor?._id && "2px solid black",
                }}
                className={style.circle}
                title={vendor?.fullName}
                onClick={() =>
                  vendor?._id !== selectedId && handleVendorChange(vendor?._id)
                }
              >
                <Badge
                  badgeContent={vendor?.pendingOrders || 0}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  invisible={!vendor?.pendingOrders}
                >
                  <img
                    src={vendor?.storeLogo}
                    alt=""
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Badge>
              </div>
            );
          }
        })}
        {state.auth?.vendors?.map((vendor) => {
          if (vendor?._id !== state?.auth?.user?.user._id) {
            return (
              <div
                style={{
                  border: selectedId === vendor?._id && "2px solid black",
                }}
                className={style.circle}
                title={vendor?.fullName}
                onClick={() =>
                  vendor?._id !== selectedId && handleVendorChange(vendor?._id)
                }
              >
                <Badge
                  badgeContent={vendor?.pendingOrders || 0}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  invisible={!vendor?.pendingOrders}
                >
                  <img
                    src={vendor?.storeLogo}
                    alt=""
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Badge>
              </div>
            );
          }
        })}
      </div>
      <div className={style.containerBody}>
        <div className={style.contentContainer}>
          <div className={style.sidebar}>
            <SideBarContainer
              selectedComp={selectedComp}
              changeTab={changeTab}
              scope={scope}
            />
          </div>
          <div className={style.mainContent}>{components[selectedComp]}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  _auth: state.auth,
  isWaiting: state.auth.waiting,
  callStack: state.auth.callStack,
});
export default connect(mapStateToProps, {
  auth,
})(DashboardContainer);
