import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from "@mui/material/Dialog";
import { blue } from "@mui/material/colors";
import Badge from "@mui/material/Badge";
import style from "./Header.module.css";
//redux
import { logout } from "../../redux/actions/auth";
import { switchVendor } from "../../services/dashboard/dashboard.services";
const Header = (props) => {
  const isDarkMode = localStorage.getItem("darkMode");
  const [abc, setabc] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleToggleDarkMode = () => {
    setabc(!abc);
    if (isDarkMode === "enabled") {
      localStorage.setItem("darkMode", null);
    } else {
      localStorage.setItem("darkMode", "enabled");
    }
  };
  useEffect(() => {
    if (isDarkMode === "enabled") document.body.classList.add("darkMode");
    else document.body.classList.remove("darkMode");
  }, [isDarkMode]);

  const handleVendorChange = async (id) => {
    handleClose();
    const token = await switchVendor(id);

    localStorage.setItem("token", token.data.token);

    window.location.reload();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getTotalCount = (vendors) => {
    return vendors.reduce((total, vendor) => total + vendor.pendingOrders, 0);
  };

  function SimpleDialog(props) {
    const { onClose, selectedValue, open, vendors, onClick } = props;

    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>Set Vendor Account</DialogTitle>
        <List sx={{ pt: 0 }}>
          {vendors.map((vendor) => (
            <ListItem
              selected={vendor._id === selectedValue}
              button
              onClick={() =>
                vendor._id !== selectedValue && onClick(vendor._id)
              }
              key={vendor}
            >
              <Badge
                badgeContent={vendor?.pendingOrders || 0}
                color="primary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                invisible={!vendor?.pendingOrders}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <img
                      src={vendor.storeLogo}
                      alt=""
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </Avatar>
                </ListItemAvatar>
              </Badge>
              <ListItemText primary={vendor.fullName} />
            </ListItem>
          ))}

          {/* <ListItem
            autoFocus
            button
            onClick={() => handleListItemClick('addAccount')}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add account" />
          </ListItem> */}
        </List>
      </Dialog>
    );
  }

 
  return (
    <div className={`${style.headerBorder} pageContainer`}>
      <header id="header" className="p-2">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <strong id="logo">
              <Link to="/" className="d-block">
                <img
                  className={style.logoImg}
                  src="https://sundialinsider.s3.us-east-2.amazonaws.com/setting/logo.svg"
                  alt=""
                />
              </Link>
            </strong>
          </div>
          {props._auth.isAuth && (
            <div>
              <div
                className={`d-flex align-items-center position-relative c-pointer`}
              >
                <button
                  onClick={() => props.logout()}
                  className="loginBtn btn p-2 no-border border-radius-8"
                >
                  Log Out
                </button>
                <button
                  onClick={handleToggleDarkMode}
                  className={`p-2 no-border border-radius-8 ${style.darkModeBtn}`}
                >
                  <span
                    className={
                      isDarkMode === "enabled" ? style.moon : style.sun
                    }
                  ></span>
                </button>
              </div>
            </div>
          )}
        </div>
      </header>
      <SimpleDialog
        selectedValue={props?._auth?.user?.user?._id}
        open={open}
        onClose={handleClose}
        onClick={handleVendorChange}
        vendors={props?._auth?.vendors || []}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  _auth: state.auth,
});

export default connect(mapStateToProps, {
  logout,
})(Header);
