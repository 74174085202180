import React, { useState, useEffect } from "react";
import * as icons from "../../../../../../Components/icons/Icons";
import { useSelector } from "react-redux";
import { apiURL } from "../../../../../../config/default";
import style from "./SellerHeader.module.css";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
const SellerHeader = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [images, setImages] = useState({
    banner: user?.profile?.storeBanner,
    logo: user?.profile?.storeLogo,
  });

  useEffect(() => {
    if (user?.profile)
      setImages({
        banner: user?.profile?.storeBanner,
        logo: user?.profile?.storeLogo,
      });
  }, [user]);

  const convertImgToBase64 = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const deleteBannerImage = async () => {
    setOpen(false);
    const res = await axios.post(`${apiURL}profile-update`, {
      storeBanner: images.banner,
      isUpdate: false,
    });
    if (res.status === 200) {
      setImages({
        ...images,
        banner: "",
      });
    }
  };

  const handleImage = async (e, imageToChange) => {
    const image = await convertImgToBase64(e.target.files[0]);
    console.log(image);
    try {
      let dataTosend = {};

      switch (imageToChange) {
        case "logo":
          dataTosend = {
            storeLogo: image,
            isUpdate: true,
            isStoreLogoBase64: true,
          };
          break;
        case "banner":
          dataTosend = {
            storeBanner: image,
            isUpdate: true,
            isBannerBase64: true,
          };
          break;

        default:
          dataTosend = {};
          break;
      }
      const res = await axios.post(`${apiURL}profile-update`, dataTosend);
      if (res.status === 200) {
        setImages({
          ...images,
          [imageToChange]: image,
        });
      }
    } catch (err) {}
  };
  return (
    <div className={`${style.hero} pt-3`}>
      {images.banner ? (
        <div className={style.cover}>
          <img
            width="100%"
            // src="https://sundialinsider.s3.us-east-2.amazonaws.com/vendors/NaN687.png"
            src={images?.banner}
            alt="Cover Image"
          />

          <div className={`${style.cCamera} ${style.camera}`}>
            <button className="bg-transparent no-border c-pointer">
              <label htmlFor="bannerImg" className="c-pointer">
                <icons.CameraPlus className="color-white" />
              </label>
              <input
                onChange={(e) => handleImage(e, "banner")}
                className="d-none"
                type="file"
                id="bannerImg"
              />
            </button>
            <span className="m-2" style={{ color: "#fff" }}>
              Change Cover Banner
            </span>
            <button
              onClick={() => setOpen(true)}
              type="button"
              title="Delete"
              className={style.dltBanner}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      ) : (
        <button
          title="Add Banner"
          className="bg-transparent no-border c-pointer"
        >
          <label
            htmlFor="bannerImg"
            className={`c-pointer ${style.addNewBannerBtn}`}
          >
            <icons.CameraPlus />
          </label>
          <input
            onChange={(e) => handleImage(e, "banner")}
            className="d-none"
            type="file"
            id="bannerImg"
          />
        </button>
      )}
      <div className="d-flex align-items-center">
        <div
          className={style.profile}
          style={{ marginTop: images.banner ? "-30px" : "0" }}
        >
          <img
            width="100%"
            // src="https://sundialinsider.s3.us-east-2.amazonaws.com/vendors/vend738.png"
            src={images.logo}
            alt="Profile Image"
          />
          <div className={`${style.pCamera} ${style.camera}`}>
            <button className="bg-transparent no-border c-pointer">
              <label htmlFor="logoImg" className="c-pointer">
                <icons.CameraPlus className="color-white" />
              </label>
              <input
                onChange={(e) => handleImage(e, "logo")}
                className="d-none"
                type="file"
                id="logoImg"
              />
            </button>
          </div>
        </div>

        <div>
          <h4 className="color-black m-2">
            {user?.user.fullName}
            <span className="m-2">
              <icons.VerifiedBadge />
            </span>
          </h4>
          <p className="m-2">
            <small>
              <i className="color-light-grey">No Description</i>
            </small>
          </p>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are Your Sure You Want To Delete Banner ?"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={deleteBannerImage} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SellerHeader;
