import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ProtectedRoute = ({ Component, user, isAuth, scope }) => {
  return (
    <Route
      render={(props) => {
        if (isAuth || scope.length > 0) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          );
        }
      }}
    />
  );
};

// export default ProtectedRoute;

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  scope: state.auth.scope,
});

export default connect(mapStateToProps, {})(ProtectedRoute);
