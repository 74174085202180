import axios from "axios";
import { apiURL } from "../../config/default";

export const getSingleProduct = async (productId) => {
  try {
    const res = await axios.get(`${apiURL}single-product/${productId}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const submitEditedProduct = async (data) => {
  try {
    const res = await axios.post(`${apiURL}update-product/`, {
      ...data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
