import React, { useState, useEffect } from "react";
import axios from "axios";
//Pages
import Login from "../components/LoginPage/Login";
import Loading from "../components/Loading/Loading";
import OTPpage from "../components/OTPpage/OTPpage";
import LinkExpired from "../components/LinkExpired/LinkExpired";
//Services
import { verifyToken } from "../../../services/otp/otp.services";
//redux
import { useSelector, useDispatch } from "react-redux";
import { setScope, auth, logout } from "../../../redux/actions/auth";

const LoginContainer = () => {
  const dispatch = useDispatch();
  const otpSuccess = (s) => {
    localStorage?.setItem("token", pinToken);
    dispatch(auth());
  };
  const [selectedPage, setSelectedPage] = useState("LoginPage");
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };
  //pages
  const pages = {
    LoginPage: <Login values={values} handleChange={handleChange} />,
    Loading: <Loading />,
    OTPpage: <OTPpage otpSuccess={(s) => otpSuccess(s)} />,
    LinkExpired: <LinkExpired toLogin={() => setSelectedPage("LoginPage")} />,
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pinToken = urlParams.get("pinToken");

  const requestOTP = async (token) => {
    const res = await verifyToken(token);
    if (res) {
      setSelectedPage("OTPpage");
    } else {
      setSelectedPage("LinkExpired");
    }
  };

  useEffect(() => {
    if (pinToken) {
      // dispatch(logout);
      setSelectedPage("Loading");
      requestOTP(pinToken);
    }
  }, []);

  return <>{pages[selectedPage]}</>;
};

export default LoginContainer;
