import React from "react";
import style from "./Loading.module.css";
const Loading = () => {
  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        zIndex: 10,
        backgroundColor: "white",
      }}
    >
      <div className='text-center  my-3'>
        <strong id='logo'>
          <img
            width='150px'
            className='object-fit-contain'
            src='https://sundialinsider.s3.us-east-2.amazonaws.com/setting/logo.svg'
            alt=''
          />
        </strong>
      </div>
      <h5 className='text-center my-3'>
        Please Wait While We Authenticate you..
      </h5>
      <div className={style.loader}>
        <img src='/assets/images/loader.svg' width='120px' />
      </div>
    </div>
  );
};

export default Loading;
