import { SNACKBAR } from "../types";

const initialState = {
  error: null,
  severity: "",
  open: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SNACKBAR:
      return {
        ...state,
        error: payload?.message ?? null,
        severity: payload?.severity ?? "",
        open: payload?.open,
      };

    default:
      return state;
  }
}
